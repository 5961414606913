@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

body {
    font-family: 'Sora', sans-serif;
    background-color: #080E10;
    color: #FFFFFF;
}


.react-datepicker-wrapper {
    width: 100%;
}
